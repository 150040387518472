/**
 * Computes the id of a contract version.
 * @param subscriptionId
 * @param versionIndex Zero-based version index in the signed version array,
 * and signed version count for the next version to be signed
 * @returns
 */
export function computeVersionedSubscriptionId(subscriptionId: string, versionIndex: number): string {
  return `${subscriptionId}/${versionIndex}`
}
